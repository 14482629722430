/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/swiper@4.5.1/dist/js/swiper.min.js
 * - /npm/jquery-mask-plugin@1.14.16/dist/jquery.mask.min.js
 * - /npm/photoswipe@4.1.3/dist/photoswipe.min.js
 * - /npm/photoswipe@4.1.3/dist/photoswipe-ui-default.min.js
 * - /npm/flatpickr@4.6.13/dist/flatpickr.min.js
 * - /npm/flatpickr@4.6.13/dist/l10n/ru.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
